import { getEnv, resolve } from 'core/utils/env';

import { dark, darkMobile, light, lightMobile } from 'site/themes';

const host = 'www.wmj.ru';
const siteUrl = 'https://' + host;

/**
 * Этот конфиг вынесен в отдельный файл чтоб шарить конфигурацию сайта
 * с галереей компонентов.
 * Сюда ни в коем случае не должно добавляться что-то кроме конфигурации
 * компонента App.
 */
export default function resolveConfig() {
  return {
    siteName: 'wmj',
    host: resolve({
      '*': host,
      'staging': getEnv('SITE_HOST'),
    }),
    theme: 'light',
    themes: {
      light: {
        base: light,
        mobile: lightMobile,
      },
      dark: {
        base: dark,
        mobile: darkMobile,
      },
    },
    coreApisConfig: {
      bebopApi: resolve({
        'dev': '//stage1.wmj.ru/api/v2',
      }),
    },
    webdavHost: resolve({
      '*': getEnv('WEBDAV_HOST'),
      'dev': '//wcdn.stage.unity.rambler.ru/wmj',
    }),
    thumborHost: resolve({
      '*': getEnv('THUMBOR_HOST'),
      'dev': '//s1.thumbor.unity.rambler.tech/unsafe',
    }),
    erratumUrl: resolve({
      '*': `${siteUrl}/erratum`,
      'staging': `https://${getEnv('SITE_HOST')}/erratum`,
    }),
    schemaOrg: {
      organizationName: 'WMJ',
      /**
       * Логотип должен быть прямоугольным и лежать в пределах 60x600px:
       * https://developers.google.com/search/docs/data-types/article#logo-guidelines
       */
      organizationLogoUrl: siteUrl + '/icons/publisher-logo.png',
      organizationLogoWidth: 243,
      organizationLogoHeight: 60,
    },

    bannersForCapirsMonitor: [
      432500202,
      458962976,
    ],

    isUnityProject: true,

    defaultSiteMeta: {
      titleTemplate: '%s | WMJ.ru',
      defaultTitle: 'WMJ.RU — онлайн журнал о моде и красоте: тренды и модные битвы, призы, новости с красных дорожек, эксперты красоты и гороскопы',
      meta: [
        { name: 'description',
          content: 'Глянцевый интернет-журнал для стильных и активных молодых девушек. Эксклюзивы со звездами, отчеты с Недель моды, статьи о сексе — у нас много интересного!',
        },
      ],
    },

    ramblerCommentsAppId: resolve({
      'production': 73,
      '*': 188,
    }),

    ads: {
      /**
       * Здесь указано значение siteId (begun-auto-pad) для десктопа.
       * Для мобильных оно лежит в site/components/Ads/mobile.js
       */
      siteId: '432499994',
      antiadblock: true,
    },

    features: {
      disableBlurPreview: true,
      useSmartResizeForSocialImage: true,
      enableRetina: true,
      enableFenwick: true,
      enableCapirsMonitor: true,
    },

    counterIds: {
      googleAnalytics: 'UA-210145-2',
      ga4: 'G-3Z8X1DHNTD',
      tns: { account: 'wmj_ru', tmsec: 'wmj_total' },
      mediascope: 'rambler_cid1100108-posid2155113/',
      top100: 1202084,
      yandexMetrika: 7399345,
      mailru: 3090289,
      rcm: resolve({
        '*': 'RCM-4440',
        'production': 'RCM-8B36',
      }),
    },

    pushWoosh: {
      applicationCode: resolve({
        '*': '6CA9A-E83D9',
        'staging': '2C00F-42726',
      }),
      safariWebsitePushID: 'web.ru.wmj',
      defaultNotificationTitle: 'wmj.ru',
      defaultNotificationImage: siteUrl + '/icons/notification-logo.png',
    },

    specialLinks: [
      '/about', // чтобы работала хеш навигация
      '/stil-zhizni/novosti/pochemu-my-pomogaem-drugim-i-kak-nauchit-etomu-detei.htm',
      '/stil-zhizni/novosti/khochu-pomogat-kak-11-voprosov-o-blagotvoritelnosti.htm',
      '/stil-zhizni/novosti/kak-bolshoi-biznes-pomogaet-lyudyam.htm',
      '/stil-zhizni/novosti/stanovimsya-li-my-dobree-7-faktov-o-blagotvoritelnosti-v-rossii.htm',
    ],

    spammer: {
      ramblerCrmProjectKey: 'wmj_ru',
      digestKey: 'product',
      termsUrl: 'https://help.rambler.ru/legal/1706/',
    },

    ramblerId: {
      rname: 'wmj',
      useAuthRedirect: true,
    },

    socials: [
      { name: 'vkontakte', link: 'https://vk.com/wmjournal' },
      { name: 'odnoklassniki', link: 'https://ok.ru/womanjournal' },
      { name: 'youtube', link: 'https://www.youtube.com/channel/UC8ceDfoF4w54a9bypdnJ4wQ' },
      { name: 'yandex_dzen', link: 'https://dzen.ru/wmj?favid=1670' },
      { name: 'telegram', link: 'https://t.me/womanjrnl' },
    ],
  };
}

import { compose } from 'core/libs/recompose';

import bindProps from 'core/components/bindProps';

import Ad from 'core/components/Ad';

import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';
import card4Type4Styles from './styles/card4-type4';
import card3Styles from './styles/card3';
import listingSpecSearchNativeStyles from './styles/listing-spec-search';

/**
 * У мобильных баннеров нужно указывать мобильный `siteId` (`begun-auto-pad`).
 */
const commonProps = {
  siteId: '458962634',
  height: 250,
};

const listingSpecProps = bindProps({
  ...commonProps,
  name: 'Listing Spec (COM)',
  lazy: true,
  blockId: '458962948',
  options: {
    p1: 'bxrvl',
    p2: 'fqbd',
    pct: 'a',
  },
});

export const TopBanner = bindProps({
  ...commonProps,
  name: 'TopBanner',
  blockId: '458962976',
  options: {
    p1: 'btrcc',
    p2: 'fexc',
    pct: 'a',
  },
})(Ad);

export const Fullscreen = bindProps({
  ...commonProps,
  name: 'Fullscreen',
  blockId: '458962772',
  height: null,
  options: {
    p1: 'btrby',
    p2: 'etsa',
    pct: 'a',
  },
})(Ad);

export const Rich = bindProps({
  ...commonProps,
  name: 'Rich',
  blockId: '458962952',
  height: null,
  options: {
    p1: 'btrca',
    p2: 'ffhi',
    pct: 'a',
  },
})(Ad);

export const Listing1 = bindProps({
  ...commonProps,
  name: 'Listing1 (COM)',
  lazy: true,
  blockId: '458962886',
  options: {
    p1: 'bwuib',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Listing2 = bindProps({
  ...commonProps,
  name: 'Listing2 (COM)',
  lazy: true,
  blockId: '458962898',
  options: {
    p1: 'bwuic',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Listing3 = bindProps({
  ...commonProps,
  name: 'Listing3 (COM)',
  lazy: true,
  blockId: '458962910',
  options: {
    p1: 'bwuie',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Listing4 = bindProps({
  ...commonProps,
  name: 'Listing4 (COM)',
  lazy: true,
  blockId: '458962938',
  options: {
    p1: 'bwuif',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const ListingSpec = compose(
  listingSpecProps,
  card3Styles,
)(Ad);

export const ListingSpecSearch = compose(
  listingSpecProps,
  listingSpecSearchNativeStyles,
)(Ad);

export const Footer = bindProps({
  ...commonProps,
  name: 'Footer (COM)',
  lazy: true,
  blockId: '458962766',
  options: {
    p1: 'bwuia',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Content1 = bindProps({
  ...commonProps,
  name: 'Content1 (COM)',
  lazy: true,
  blockId: '458962694',
  options: {
    p1: 'bwuhw',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Content2 = bindProps({
  ...commonProps,
  name: 'Content2 (COM)',
  lazy: true,
  blockId: '458962700',
  options: {
    p1: 'bwuhx',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Content3 = bindProps({
  ...commonProps,
  name: 'Content3 (COM)',
  lazy: true,
  blockId: '458962714',
  options: {
    p1: 'bwuhy',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Content4 = bindProps({
  ...commonProps,
  name: 'Content4 (COM)',
  lazy: true,
  blockId: '458962742',
  options: {
    p1: 'bwuhz',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const ContentSpec = compose(
  bindProps({
    ...commonProps,
    name: 'Content Spec (COM)',
    lazy: true,
    blockId: '458962764',
    height: 300,
    options: {
      p1: 'bwuia',
      p2: 'fqbd',
      pct: 'a',
    },
  }),
  card4Type4Styles,
)(Ad);

export const InPage = bindProps({
  ...commonProps,
  name: 'InPage',
  blockId: '458962884',
  height: 200,
  options: {
    p1: 'btrce',
    p2: 'fexd',
    pct: 'a',
  },
})(Ad);

export const Sponsored = compose(
  bindProps({
    ...commonProps,
    name: 'Sponsored (COM)',
    blockId: '458962956',
    height: null,
    options: {
      p1: 'bwujy',
      p2: 'fooq',
      pct: 'c',
    },
  }),
  sponsoredStyles,
)(Ad);

export const InGallery = bindProps({
  ...commonProps,
  name: 'InGallery',
  blockId: '579389980',
  disableAutoInit: true,
  height: null,
})(Ad);
